import React from 'react'
import moment from 'moment'

import { TimePicker, Select } from 'antd'

class RaidSettings extends React.Component {
  render() {
    const difficulties = ['Mythic', 'Heroic', 'Normal', 'LFR']
    return (
      <React.Fragment>
        <div className={this.props.showLabels ? 'field time' : ''}>
          {this.props.showLabels && <label>Start</label>}
          <TimePicker
            type='date'
            format='HH:mm'
            id={`start-time-${this.props.raid.day}`}
            placeholder='Start'
            defaultValue={
              this.props.raid.start_time
                ? moment(this.props.raid.start_time, 'HH:mm')
                : moment('00:00', 'HH:mm')
            }
            disabled={!this.props.active}
            onChange={(value) =>
              this.props.updateRaid(
                this.props.raid,
                'start_time',
                (value && value.format('HH:mm')) || '',
              )
            }
          />
        </div>

        <div className={this.props.showLabels ? 'field time' : ''}>
          {this.props.showLabels && <label>End</label>}
          <TimePicker
            type='date'
            format='HH:mm'
            id={`end-time-${this.props.raid.day}`}
            placeholder='End'
            defaultValue={
              this.props.raid.end_time
                ? moment(this.props.raid.end_time, 'HH:mm')
                : moment('00:00', 'HH:mm')
            }
            disabled={!this.props.active}
            onChange={(value) =>
              this.props.updateRaid(
                this.props.raid,
                'end_time',
                (value && value.format('HH:mm')) || '',
              )
            }
          />
        </div>

        <div className='instance'>
          {this.props.showLabels && <label>Instance</label>}
          <Select
            placeholder='Instance'
            value={this.props.instances && this.props.raid.instance_id}
            loading={!this.props.instances}
            disabled={!this.props.active}
            optionFilterProp='children'
            onChange={(value) =>
              this.props.updateRaid(this.props.raid, 'instance_id', value)
            }
          >
            {this.props.instances &&
              this.props.instances.map((instance, _) => (
                <Select.Option key={`inst-${instance.id}`} value={instance.id}>
                  {instance.name}
                </Select.Option>
              ))}
          </Select>
        </div>

        {(this.props.guild.kind === 'live' ||
          this.props.guild.kind === 'classic_progression') && (
          <div className='difficulty'>
            {this.props.showLabels && <label>Difficulty</label>}
            <Select
              placeholder='Difficulty'
              defaultValue={this.props.raid.difficulty}
              disabled={!this.props.active}
              optionFilterProp='children'
              onChange={(value) =>
                this.props.updateRaid(this.props.raid, 'difficulty', value)
              }
            >
              {difficulties.map((difficulty, index) => (
                <Select.Option key={`diff-${index}`} value={index}>
                  {difficulty}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}

        <div className='default-status'>
          {this.props.showLabels && <label>Default status</label>}
          <Select
            placeholder='Status'
            defaultValue={
              this.props.raid.default_signup_status ||
              this.props.guild.selectedTeam.default_signup_status
            }
            optionFilterProp='children'
            onChange={(value) =>
              this.props.updateRaid(
                this.props.raid,
                'default_signup_status',
                value,
              )
            }
          >
            {[
              'present',
              'absent',
              'tentative',
              'late',
              'standby',
              'unknown',
            ].map((status, index) => (
              <Select.Option key={index} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </div>
      </React.Fragment>
    )
  }
}

export default RaidSettings
