import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

import { message } from 'antd'

class TeamService {
  async create(guildId, name) {
    return await axios.post(`/api/guilds/${guildId}/teams`, {
      team: { name: name, guild_id: guildId },
    })
  }

  async delete(guildId, id) {
    return await axios.delete(`/api/guilds/${guildId}/teams/${id}`)
  }

  async clearWishlists(guildId, id) {
    return await axios.delete(
      `/api/guilds/${guildId}/teams/${id}/clear_wishlists`,
    )
  }

  async update(guildId, id, team) {
    return await axios.put(`/api/guilds/${guildId}/teams/${id}`, { team })
  }

  async resetSignups(guildId, id, team) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${id}/regenerate_raids`,
      { team },
    )
  }

  async refreshType(guildId, id, refresh_type) {
    return await axios.put(`/api/guilds/${guildId}/teams/${id}`, {
      team: { refresh_type },
    })
  }

  async revokePatreonStatus(guildId, id) {
    const response = await fetch(
      `/api/guilds/${guildId}/teams/${id}/revoke_benefits`,
      { credentials: 'include' },
    )
    return await response.json()
  }

  async fetchRaids(guildId, id, date) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${id}/raids?date=${date}`,
    )
  }

  async fetchRaid(guildId, teamId, id) {
    return await axios.get(`/api/guilds/${guildId}/teams/${teamId}/raids/${id}`)
  }

  async fetchRaidStatistics(guildId, teamId, filters) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${teamId}/raid_statistics`,
      { params: filters },
    )
  }

  async fetchLootWishlist(
    guildId,
    teamId,
    difficulty,
    instance,
    configuration_id,
  ) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${teamId}/loot_wishlist?difficulty=${difficulty}&instance=${instance}${
        configuration_id ? `&configuration_id=${configuration_id}` : ''
      }`,
    )
  }

  async fetchDashboardData(guildId, teamId) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${teamId}/dashboard_data`,
    )
  }

  async fetchRefreshStatus(guildId, id) {
    return await axios.get(`/api/guilds/${guildId}/teams/${id}/refresh_status`)
  }

  async toggleApi(guildId, teamId) {
    return await axios.put(`/api/guilds/${guildId}/teams/${teamId}/toggle_api`)
  }

  async fetchBlizzardMembers(guildId, id) {
    return await axios
      .get(`/api/guilds/${guildId}/teams/${id}/blizzard_list`)
      .then((result) => {
        return result.data.characters
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Retrieving guild list from Blizzard failed. Please try again later.',
          5,
        )
        return []
      })
  }

  async fetchUpcomingRaids(guildId, id, limit) {
    return await axios
      .get(
        `/api/guilds/${guildId}/teams/${id}/upcoming_raids${
          limit ? `?limit=${limit}` : ''
        }`,
      )
      .then((result) => {
        return result.data.raids
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Retrieving upcoming raids failed. Please try again later.',
          5,
        )
        return []
      })
  }
}

export default new TeamService()
