import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { useSetting } from '../helpers'
import Image from '../components/Image'
import { subdomainForKind } from '../containers/Home'
import { Menu, Icon, Select } from 'antd'
import discordLogo from '../../../../assets/images/discord-logo-white.svg'
const DiscordLogo = () => <img src={discordLogo} />
const { Option } = Select

const SidebarContent = (props) => {
  const currentTeam = props.guild.selectedTeam
  const overviewVisibility = useSetting(props, 'wishlist_overview_visibility')

  const header = () => {
    return (
      <div className='sider-header'>
        <span className='logo'>
          <a className='logo' href='/'>
            <Image name='new-logo' extension='svg' />
          </a>
        </span>

        <Icon type='menu' className='mobile-menu' onClick={props.toggleMenu} />

        <h2>
          {props.guild.name}
          <span className='realm'>
            {props.guild.realm}-{props.guild.region}
          </span>
        </h2>

        {props.user.accessLevel <= 2 && (
          <Select
            value={currentTeam.id}
            onChange={(value) => props.changeTeam(value)}
            dropdownRender={(dropdown) => (
              <div>
                {props.user.accessLevel == 0 && (
                  <li
                    className='ant-select-dropdown-menu-item'
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={props.openNewTeamModal}
                  >
                    <Icon type='plus' /> Create team
                  </li>
                )}
                {dropdown}
              </div>
            )}
            optionFilterProp='children'
          >
            {props.guild.teams.map((team, index) => (
              <Option key={team.id} value={team.id}>
                {team.name}
              </Option>
            ))}
          </Select>
        )}

        {/* Only show when the user is logged in AND has no admin access AND there are Google accounts linked */}
        {props.user.loggedIn &&
          props.user.accessLevel != 0 &&
          props.user.method == 'bnet' && (
            <li className='ant-menu-item'>
              <a href='/auth/google_oauth2'>
                <Icon type='login' />
                <span>
                  Log In <span className='battletag'>(Admin)</span>
                </span>
              </a>
            </li>
          )}

        {!props.user.loggedIn && (
          <li className='ant-menu-item'>
            <a
              href={`/auth/bnet?origin=${encodeURIComponent(
                props.match.url,
              )}&region=${props.guild.region}`}
            >
              <Icon type='login' />
              <span>Log In</span>
            </a>
          </li>
        )}

        {props.user.loggedIn && (
          <React.Fragment>
            {props.user.bnet && (
              <li className='ant-menu-item'>
                <Link to={`${props.guild.selectedTeam.path}/profile`}>
                  <Icon type='user' />
                  <span>Profile</span>
                </Link>
              </li>
            )}

            <li className='ant-menu-item'>
              <a href='/logout'>
                <Icon type='logout' />
                <span>
                  Log Out <span className='battletag'>({props.user.name})</span>
                </span>
              </a>
            </li>
          </React.Fragment>
        )}
      </div>
    )
  }

  return (
    <div className='sider'>
      {props.user.accessLevel == 3 && (
        <Menu
          theme='light'
          mode='inline'
          selectedKeys={
            currentTeam.active
              ? [`${props.path.category || ''}/${props.path.page || ''}`]
              : ['settings/']
          }
        >
          {header()}

          <h3>General</h3>
          <Menu.Item key='/'>
            <Link
              to={`${currentTeam.path}`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='dashboard' />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
        </Menu>
      )}

      {props.user.accessLevel < 3 && (
        <Menu
          theme='light'
          mode='inline'
          selectedKeys={
            currentTeam.active
              ? [`${props.path.category || ''}/${props.path.page || ''}`]
              : ['settings/']
          }
        >
          {header()}

          <h3>General</h3>
          <Menu.Item key='/'>
            <Link
              to={`${currentTeam.path}`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='dashboard' />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='roster/'>
            <Link
              to={`${currentTeam.path}/roster`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='team' />
              <span>Roster</span>
              <span className='character-amount'>
                ({currentTeam.characters.length})
              </span>
            </Link>
          </Menu.Item>
          {props.user.accessLevel == 0 && props.user.loggedIn && (
            <Menu.Item key='access/'>
              <Link
                to={`${currentTeam.path}/access`}
                disabled={!currentTeam.active}
                onClick={props.toggleMenu}
              >
                <Icon type='unlock' />
                <span>Access Control</span>
              </Link>
            </Menu.Item>
          )}
          {props.user.accessLevel < 3 && (
            <Menu.Item key='premium/'>
              <Link
                to={`${currentTeam.path}/premium`}
                disabled={!currentTeam.active}
                onClick={props.toggleMenu}
              >
                <Icon type='star' />
                <span>Patreon</span>
              </Link>
            </Menu.Item>
          )}
          {props.user.accessLevel == 0 && props.user.loggedIn && (
            <Menu.Item key='api/'>
              <Link
                to={`${currentTeam.path}/api`}
                disabled={!currentTeam.active}
                onClick={props.toggleMenu}
              >
                <Icon type='api' />
                <span>API</span>
              </Link>
            </Menu.Item>
          )}
          <li className='ant-menu-item'>
            <a
              href='https://discord.gg/86eUAFz'
              target='_blank'
              rel='noreferrer'
            >
              <Icon type='question-circle' />
              <span>Help</span>
            </a>
          </li>
          {props.user.accessLevel == 0 && (
            <Menu.Item key='settings/'>
              <Link
                to={`${currentTeam.path}/settings`}
                onClick={props.toggleMenu}
              >
                <Icon type='setting' />
                <span>Settings</span>
              </Link>
            </Menu.Item>
          )}

          <h3>Spreadsheet</h3>
          <li className='ant-menu-item'>
            <a
              href={`https://wowaudit.com/sheet${props.guild.selectedTeam.path}`}
              target='_blank'
              rel='noreferrer'
              disabled={!currentTeam.spreadsheet_url}
              className={!currentTeam.spreadsheet_url ? 'disabled' : ''}
            >
              <Icon type='link' />
              <span>Link</span>
            </a>
          </li>
          {props.user.accessLevel == 0 && (
            <li className='ant-menu-item'>
              <a
                href={`https://${
                  subdomainForKind[props.guild.kind] || ''
                }wowaudit.com/demo`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon type='experiment' />
                <span>Demo</span>
              </a>
            </li>
          )}
          <Menu.Item key='spreadsheet/status'>
            <Link
              to={`${currentTeam.path}/spreadsheet/status`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='sync' />
              <span>Refresh Status</span>
            </Link>
          </Menu.Item>

          <h3>Raids</h3>
          <Menu.Item key='raids/'>
            <Link
              to={`${currentTeam.path}/raids`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='calendar' />
              <span>Calendar</span>
            </Link>
          </Menu.Item>
          {props.user.accessLevel <= 2 && (
            <Menu.Item key='raids/upcoming'>
              <Link
                to={`${currentTeam.path}/raids/upcoming`}
                disabled={!currentTeam.active}
                onClick={props.toggleMenu}
              >
                <Icon type='contacts' />
                <span>Upcoming</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key='raids/statistics'>
            <Link
              to={`${currentTeam.path}/raids/statistics`}
              disabled={!currentTeam.active}
              onClick={props.toggleMenu}
            >
              <Icon type='bar-chart' />
              <span>Statistics</span>
            </Link>
          </Menu.Item>

          <Menu.Item key='discord_bot/' className='discord-logo'>
            <Link
              to={`${currentTeam.path}/discord_bot`}
              onClick={props.toggleMenu}
            >
              <Icon component={DiscordLogo} />
              <span>Discord Bot</span>
            </Link>
          </Menu.Item>

          {props.guild.kind === 'live' && <h3>Wishlists</h3>}

          {props.guild.kind === 'live' &&
            (overviewVisibility !== 'admins_only' ||
              props.user.accessLevel == 0) && (
              <Menu.Item key='wishlists/overview'>
                <Link
                  to={`${currentTeam.path}/wishlists/overview`}
                  disabled={!currentTeam.active}
                  onClick={props.toggleMenu}
                >
                  <Icon type='profile' />
                  <span>Overview</span>
                </Link>
              </Menu.Item>
            )}

          {props.guild.kind === 'live' && (
            <Menu.Item key='wishlists/personal'>
              <Link
                to={`${currentTeam.path}/wishlists/personal`}
                disabled={!currentTeam.active}
                onClick={props.toggleMenu}
              >
                <Icon type='edit' />
                <span>Personal</span>
              </Link>
            </Menu.Item>
          )}

          <h3>Recruitment</h3>
          {props.user.accessLevel <= 1 && (
            <li className='ant-menu-item'>
              <a
                href={`https://apply.wowaudit.com${currentTeam.path}`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon type='unordered-list' />
                <span>Applications</span>
              </a>
            </li>
          )}
          {(props.user.accessLevel > 1 || props.user.accessLevel == 0) && (
            <li className='ant-menu-item'>
              <a
                href={`https://apply.wowaudit.com${currentTeam.path}?preview`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon type='form' />
                <span>Form</span>
              </a>
            </li>
          )}
          {props.user.accessLevel == 0 && (
            <li className='ant-menu-item'>
              <a
                href={`https://apply.wowaudit.com${currentTeam.path}?settings`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon type='setting' />
                <span>Settings</span>
              </a>
            </li>
          )}
        </Menu>
      )}
    </div>
  )
}

export default withRouter(SidebarContent)
