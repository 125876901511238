export const useSetting = (props, setting) => {
  return props.guild.selectedTeam.settings.find((s) => s.field === setting)
    ?.value
}

export const difficultyOptions = {
  mythic: 'Mythic',
  heroic: 'Heroic',
  normal: 'Normal',
  raid_finder: 'LFR',
}
