import React from 'react'
import { Link } from 'react-router-dom'

import { Icon, Alert } from 'antd'

import RosterTable from '../../../components/roster/RosterTable'
import BlizzardTable from '../../../components/roster/BlizzardTable'
import NewMemberForm from '../../../components/roster/NewMemberForm'
import TrackingRulesForm from '../../../components/roster/TrackingRulesForm'

import TeamService from '../../../services/TeamService'

import { Element, scroller } from 'react-scroll'

class RosterPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addingCharacters: false,
    }
  }

  componentDidMount() {
    this.fetchBlizzardData()
  }

  fetchBlizzardData = () => {
    TeamService.fetchBlizzardMembers(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
    ).then((blizzardCharacters) => this.setState({ blizzardCharacters }))
  }

  setAddingMethod = (method) => {
    this.setState({ addingCharacters: method })
    scroller.scrollTo(method, {
      duration: 500,
      delay: 150,
      smooth: true,
      offset: -260,
    })
  }

  render() {
    return (
      <div className='roster-page'>
        <h1>
          <div className='title-caption'>
            Team {this.props.guild.selectedTeam.name}
          </div>
          <Icon type='team' />
          Roster ({this.props.guild.selectedTeam.characters.length})
        </h1>

        {this.props.guild.selectedTeam.characters.length >=
          this.props.guild.characterLimit && (
          <Alert
            message={
              <React.Fragment>
                Your team&apos;s character limit of{' '}
                {this.props.guild.characterLimit} has been reached. You can go
                to the
                <Link to={`${this.props.guild.selectedTeam.path}/premium`}>
                  {' '}
                  Patreon page
                </Link>{' '}
                to increase it.
              </React.Fragment>
            }
            type='warning'
            showIcon
          />
        )}

        <RosterTable
          guild={this.props.guild}
          user={this.props.user}
          refreshGuild={this.props.refreshGuild}
        />

        {this.props.user.accessLevel == 0 && (
          <React.Fragment>
            <h2>Add characters</h2>

            <p>
              Choose a method to add characters to your roster.{' '}
              <span className='warning'>
                Only characters that are level 10 or higher can be found and
                tracked.
              </span>
            </p>

            <div className='tracking-methods'>
              <div
                className={`method ${
                  this.state.addingCharacters == 'tracking-rules'
                    ? 'selected'
                    : ''
                }`}
                onClick={(_) => this.setAddingMethod('tracking-rules')}
              >
                <h3>Track automatically</h3>
                <span>
                  Set up rules to automatically track new members in the future
                </span>
              </div>

              <div
                className={`method ${
                  this.state.addingCharacters == 'select-characters'
                    ? 'selected'
                    : ''
                }`}
                onClick={(_) => this.setAddingMethod('select-characters')}
              >
                <h3>Select characters</h3>
                <span>
                  Choose characters to track from a list of all guild members
                </span>
              </div>

              <div
                className={`method ${
                  this.state.addingCharacters == 'add-manually'
                    ? 'selected'
                    : ''
                }`}
                onClick={(_) => this.setAddingMethod('add-manually')}
              >
                <h3>Add manually</h3>
                <span>Search for any character to track</span>
              </div>
            </div>

            <Element name='tracking-rules'>
              {this.state.addingCharacters == 'tracking-rules' && (
                <section>
                  <h3>Track automatically</h3>
                  <p>
                    Use this form to track characters automatically. Characters
                    that are being tracked based on these rules cannot be
                    untracked manually. Only max level characters will be
                    tracked automatically. Updating the tracking rules will
                    immediately update your roster; afterwards it will
                    automatically be updated once per day (you can always
                    trigger an update here).
                  </p>

                  <TrackingRulesForm
                    guild={this.props.guild}
                    refreshGuild={this.props.refreshGuild}
                    characters={this.state.blizzardCharacters}
                  />
                </section>
              )}
            </Element>

            <Element name='select-characters'>
              {this.state.addingCharacters == 'select-characters' && (
                <section>
                  <h3>Select characters</h3>
                  <p>
                    Use the table below to add more guild members to your
                    team&apos;s roster. It can take a couple of days for
                    characters to appear when they join the guild. Characters
                    that you add using this method will not be automatically
                    untracked based on your tracking rules.
                  </p>

                  <BlizzardTable
                    guild={this.props.guild}
                    refreshGuild={this.props.refreshGuild}
                    characters={this.state.blizzardCharacters}
                  />
                </section>
              )}
            </Element>

            <Element name='add-manually'>
              {this.state.addingCharacters == 'add-manually' && (
                <section>
                  <h3>Add characters manually</h3>
                  <p>
                    Use this form to add characters to your team&apos;s roster
                    that are not member of your guild. You can even track
                    characters from the opposing faction or from another realm
                    in your region. Characters that you manually add here will
                    not be automatically untracked based on your tracking rules.
                  </p>

                  <NewMemberForm
                    guild={this.props.guild}
                    refreshGuild={this.props.refreshGuild}
                  />
                </section>
              )}
            </Element>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default RosterPage
