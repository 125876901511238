/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { useDroppable } from '@dnd-kit/core'

const EncounterDroppable = ({ orientation, id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `${orientation}-${id}`,
  })

  return (
    <span
      className='droppable-zone'
      css={DroppableStyles(orientation, isOver)}
      ref={setNodeRef}
    />
  )
}

const DroppableStyles = (orientation, onHover) => css`
  position: absolute;
  width: 100%;
  height: ${orientation === 'before' ? '2rem' : '1.7rem'};
  top: ${orientation === 'before' ? '-0.1rem' : '1.9rem'};
  border-${orientation === 'before' ? 'top' : 'bottom'}: 0.1rem dashed white;
  border-color: ${onHover ? 'white' : 'transparent'};
};
`

export default EncounterDroppable
