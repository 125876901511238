import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './containers/Home'
import PrivacyPolicy from './containers/PrivacyPolicy'
import Changelog from './containers/Changelog'
import LinkDiscord from './containers/LinkDiscord'
import ProfilePage from './containers/ProfilePage'
import Guild from './containers/Guild'
import Exception from './containers/Exception'

class App extends React.Component {
  state = {
    hasException: false,
  }

  componentDidCatch() {
    this.setState({ hasException: true })
  }

  render() {
    return (
      <Switch>
        {this.state.hasException && <Exception />}
        {!this.state.hasException && (
          <React.Fragment>
            <Route exact path='/' component={Home} />
            <Route exact path='/changelog' component={Changelog} />
            <Route exact path='/profile' component={ProfilePage} />
            <Route exact path='/privacy' component={PrivacyPolicy} />
            <Route exact path='/link_discord' component={LinkDiscord} />
            <Route
              path='/:region/:realm/:guild/:team?/:category?/:page?'
              component={Guild}
            />
          </React.Fragment>
        )}
      </Switch>
    )
  }
}

export default App
