import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react'
import { flatten, groupBy, maxBy, orderBy } from 'lodash'
import { Button, Icon, Input, Modal, Spin, Tooltip } from 'antd'
import raidbotsLogoSmall from '../../../../assets/images/raidbots-logo-head.svg'
import qeLogo from '../../../../assets/images/qe-logo.png'

import SpecWeightModal from './SpecWeightModal'
import WishlistService from '../services/WishlistService'
import { useSetting } from '../helpers'
import moment from 'moment'

export const difficultiesILVLDeficit = {
  Mythic: 0,
  Heroic: -13,
  Normal: -26,
  LFR: -39,
}

const Wishlist = ({
  character,
  instance,
  editable,
  changes,
  setChanges,
  uploadedReport,
  valueDisplay,
  configuration,
  difficulty,
  setDifficulty,
  props,
}) => {
  const [loading, setLoading] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [wishlist, setWishlist] = useState()
  const [groupedWishlist, setGroupedWishlist] = useState()
  const [defaultSpec, setDefaultSpec] = useState()
  const [view, setView] = useState('display_slot')
  const [viewingAge, setViewingAge] = useState(false)
  const [editingWeights, setEditingWeights] = useState(false)
  const [editingComment, setEditingComment] = useState()
  const [currentComment, setCurrentComment] = useState()
  const [adjustedWeights, setAdjustedWeights] = useState({})
  const enabledDifficulties = useSetting(props, 'wishlist_difficulties')

  useEffect(() => {
    setLoading(true)
    WishlistService.retrieve(props.guild.id, character.id, instance.id).then(
      (result) => {
        setWishlist(result.data)
        setDefaultSpec(result.data.specs.find((spec) => spec.default)?.id)
        setLoading(false)
      },
    )
  }, [character, instance])

  useEffect(() => {
    if (uploadedReport) {
      if (uploadedReport.difficulty) setDifficulty(uploadedReport.difficulty)
      if (uploadedReport.specialization)
        setDefaultSpec(uploadedReport.specialization)
      setWishlist(uploadedReport)
    }
  }, [uploadedReport])

  useEffect(() => {
    setCurrentComment(editingComment ? editingComment[1]?.comment : undefined)
  }, [editingComment])

  useLayoutEffect(() => {
    if (wishlist) {
      setGroupedWishlist(
        groupBy(
          orderBy(
            wishlist.items.filter((item) => item.specs.includes(defaultSpec)),
            [(i) => relevantWish(i)?.percentage || 0],
            ['desc'],
          ),
          view,
        ),
      )
    }
  }, [wishlist, view, defaultSpec])

  const deleteWishlist = () => {
    if (
      confirm('Are you sure you want to delete all your current wishlist data?')
    ) {
      setLoadingDelete(true)
      WishlistService.delete(props.guild.id, character.id, instance.id).then(
        (result) => {
          setWishlist(result.data)
          setDefaultSpec(result.data.specs.find((spec) => spec.default)?.id)
          setLoadingDelete(false)
          setViewingAge(false)
        },
      )
    }
  }

  const history = useMemo(() => {
    if (wishlist?.specs) {
      return (
        <div className='wishlist-age-modal'>
          {props.guild.selectedTeam.wishlist_configurations.map((config) => (
            <div key={config.id} className='config-age'>
              {props.guild.selectedTeam.wishlist_configurations.length > 1 && (
                <h3>{config.name}</h3>
              )}
              <div className='difficulties'>
                {Object.keys(difficultiesILVLDeficit).map((diff, i) => {
                  if (
                    enabledDifficulties.includes(
                      diff === 'LFR' ? 'raid_finder' : diff.toLowerCase(),
                    )
                  ) {
                    return (
                      <div key={diff} className='difficulty-age'>
                        {enabledDifficulties.length > 1 && <h4>{diff}</h4>}
                        <div className='specs'>
                          {wishlist.specs.map((spec) => {
                            const relevantWishes = flatten(
                              wishlist.items.map((item) =>
                                item.wishes.filter(
                                  (wish) =>
                                    wish.difficulty === i &&
                                    wish.specialization === spec.id &&
                                    wish.configuration_id === config.id,
                                ),
                              ),
                            )

                            const reportUrl = relevantWishes.filter(
                              (wish) => wish.report_url,
                            )[0]?.report_url

                            const age = maxBy(
                              relevantWishes,
                              (wish) => new Date(wish.updated_at),
                            )?.updated_at

                            const difference = moment().diff(
                              moment(age),
                              'days',
                            )

                            return (
                              <div key={spec.id} className='spec-age'>
                                <figure>
                                  <img
                                    alt={spec.name}
                                    title={spec.name}
                                    src={`https://render.worldofwarcraft.com/us/icons/36/${spec.icon}.jpg`}
                                  />
                                </figure>
                                {React.createElement(
                                  reportUrl ? `a` : `span`,
                                  {
                                    className: age
                                      ? difference > 21
                                        ? 'month'
                                        : difference > 7
                                        ? 'week'
                                        : 'day'
                                      : 'never',
                                    href: reportUrl,
                                    target: '_blank',
                                    rel: 'noreferrer',
                                  },
                                  age ? moment(age).fromNow() : 'never',
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ))}
        </div>
      )
    }
  }, [wishlist])

  const relevantWish = (item) => {
    if (changes[wishId(item)]) return changes[wishId(item)]

    return item.wishes.find(
      (wish) =>
        wish.difficulty === difficulty &&
        wish.specialization === defaultSpec &&
        configuration.id === wish.configuration_id,
    )
  }

  const wishId = (item) => {
    return `${item.id}-${difficulty}-${defaultSpec}-${configuration.id}`
  }

  const currentSettingForWish = (item, wish) => {
    if (changes[wishId(item)]) {
      return changes[wishId(item)].type
    } else {
      return wish?.type
    }
  }

  const setEdit = (item, wish, change) => {
    setChanges(
      Object.assign({}, changes, {
        [wishId(item)]: Object.assign({}, wish || { percentage: 0 }, change),
      }),
    )
  }

  const undoEdit = (item, wish) => {
    setEdit(item, wish, {
      upgrade_level: 'none',
      type: 'none',
      custom_percentage: null,
    })
  }

  const wishEmpty = (wish) => {
    return !wish || wish.type === 'none'
  }

  const cacheAdjustedWeights = (changes) => {
    const newAdjustedWeights = { ...adjustedWeights }
    newAdjustedWeights[character.id] = changes
    setAdjustedWeights(newAdjustedWeights)
  }

  if (loading) {
    return (
      <Spin
        id='wishlist-loading'
        spinning={loading}
        size='large'
        tip='Loading wishlist ...'
      />
    )
  } else {
    return (
      <div className='wishlist'>
        <div className='toolbar'>
          <div className='difficulty-picker'>
            {enabledDifficulties.length > 1 && (
              <React.Fragment>
                <h4>Difficulty</h4>
                <ul>
                  {Object.keys(difficultiesILVLDeficit).map((diff, i) => {
                    if (
                      enabledDifficulties.includes(
                        diff === 'LFR' ? 'raid_finder' : diff.toLowerCase(),
                      )
                    ) {
                      return (
                        <li
                          key={diff}
                          onClick={() => setDifficulty(i)}
                          className={i === difficulty ? 'active' : ''}
                        >
                          {diff}
                        </li>
                      )
                    }
                  })}
                </ul>
              </React.Fragment>
            )}
          </div>
          {wishlist?.specs && (
            <div className='spec-picker'>
              <h4>
                Specialization{' '}
                <Tooltip
                  title='If you set your wishlist for multiple specializations, upgrades will be averaged based on the weight you give to each specialization.'
                  placement='left'
                >
                  <Icon type='info-circle' />
                </Tooltip>
              </h4>

              <ul>
                {wishlist.specs.map((spec) => {
                  const weight =
                    adjustedWeights[character.id] !== undefined &&
                    adjustedWeights[character.id][spec.id] !== undefined
                      ? adjustedWeights[character.id][spec.id]
                      : spec.weight
                  return (
                    <li
                      key={spec.id}
                      className={spec.id === defaultSpec ? 'active' : ''}
                    >
                      <figure
                        onClick={() => {
                          if (spec.id !== defaultSpec)
                            setGroupedWishlist(undefined)
                          setDefaultSpec(spec.id)
                        }}
                      >
                        <img
                          alt={spec.name}
                          title={spec.name}
                          src={`https://render.worldofwarcraft.com/us/icons/36/${spec.icon}.jpg`}
                        />
                      </figure>
                      <span onClick={() => setEditingWeights(true)}>
                        {weight !== null && (
                          <React.Fragment>
                            <span className='info'>{weight}%</span>
                            <a>edit</a>
                          </React.Fragment>
                        )}
                      </span>
                    </li>
                  )
                })}

                <SpecWeightModal
                  specs={wishlist.specs}
                  editingWeights={editingWeights}
                  setEditingWeights={setEditingWeights}
                  adjustedWeights={adjustedWeights[character.id]}
                  setAdjustedWeights={cacheAdjustedWeights}
                  guildId={props.guild.id}
                  character={character}
                />
              </ul>
            </div>
          )}
        </div>

        <div className='wishlist-container'>
          <div className='toggle-view'>
            <h3>
              <span>View by</span>{' '}
              <span
                onClick={() => setView('display_slot')}
                className={view === 'display_slot' ? 'active toggle' : 'toggle'}
              >
                Item slot
              </span>
              <span>/</span>
              <span
                onClick={() => setView('encounter_name')}
                className={
                  view === 'encounter_name' ? 'active toggle' : 'toggle'
                }
              >
                Encounter
              </span>
              <Icon type='clock-circle' onClick={() => setViewingAge(true)} />
              <Modal
                visible={viewingAge}
                title='Time since last update'
                onOk={() => setViewingAge(false)}
                onCancel={() => setViewingAge(false)}
                footer={[
                  <Button
                    type='danger'
                    key='delete'
                    loading={loadingDelete}
                    onClick={() => deleteWishlist()}
                  >
                    Reset wishlist
                  </Button>,
                  <Button key='back' onClick={() => setViewingAge(false)}>
                    Close
                  </Button>,
                ]}
              >
                {history}
              </Modal>
              <Modal
                visible={!!editingComment}
                title='Edit comment'
                onOk={() => {
                  setEdit(editingComment[0], editingComment[1], {
                    comment: currentComment,
                  })
                  setEditingComment(undefined)
                }}
                onCancel={() => setEditingComment(undefined)}
                footer={[
                  <Button
                    key='back'
                    onClick={() => setEditingComment(undefined)}
                  >
                    Cancel
                  </Button>,
                  <Button
                    key='submit'
                    type='primary'
                    onClick={() => {
                      setEdit(editingComment[0], editingComment[1], {
                        comment: currentComment,
                      }),
                        setEditingComment(undefined)
                    }}
                  >
                    OK
                  </Button>,
                ]}
              >
                <Input
                  value={currentComment}
                  onChange={(event) => setCurrentComment(event.target.value)}
                />
              </Modal>
            </h3>
          </div>
          {groupedWishlist
            ? wishlist.order[view].map((category) => {
                if (groupedWishlist[category]) {
                  return (
                    <div className='category' key={category}>
                      <h4>{category.replaceAll('_', ' ')}</h4>

                      <div className='items-container'>
                        {groupedWishlist[category].map((item) => {
                          const wish = relevantWish(item)
                          return (
                            <div
                              className={`${
                                !wishEmpty(wish)
                                  ? `need${
                                      wish.type !== 'raidbots' &&
                                      wish.type !== 'qe'
                                        ? ' edited'
                                        : ''
                                    }${
                                      wish.outdated && wish.type === 'raidbots'
                                        ? ' outdated'
                                        : ''
                                    }`
                                  : ''
                              } item${editable ? ' editable' : ''}`}
                              key={item.id}
                              style={{
                                minWidth: 'min(31rem, calc(100vw - 10rem))',
                              }}
                            >
                              <div className='item-container'>
                                <a
                                  target='_blank'
                                  rel='noreferrer'
                                  href={`https://wowhead.com/${
                                    props.guild.kind === 'classic_progression'
                                      ? 'cata/'
                                      : ''
                                  }item=${item.blizzard_id}${
                                    item.item_level
                                      ? `?ilvl=${
                                          item.item_level +
                                          Object.values(
                                            difficultiesILVLDeficit,
                                          )[difficulty]
                                        }`
                                      : ''
                                  }${wish ? `&bonus=${wish.bonus_ids}` : ''}`}
                                >
                                  <figure>
                                    <img
                                      src={`https://render.worldofwarcraft.com/us/icons/36/${
                                        item.icon
                                      }${
                                        item.icon?.includes('.jpg')
                                          ? ''
                                          : '.jpg'
                                      }`}
                                    />
                                  </figure>
                                </a>

                                <div className='details'>
                                  <div
                                    className={`name ${item.quality} ${
                                      wish?.catalyst ? 'catalyst' : ''
                                    }`}
                                  >
                                    {wish?.catalyst ? (
                                      <Tooltip title='Catalyst' placement='top'>
                                        <figure>
                                          <img
                                            src={`https://render.worldofwarcraft.com/us/icons/36/inv_progenitor_protoformsynthesis.jpg`}
                                          />
                                        </figure>
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                    {item.name}
                                  </div>
                                  <div className='meta'>
                                    {item.item_level && (
                                      <strong>
                                        {item.item_level +
                                          Object.values(
                                            difficultiesILVLDeficit,
                                          )[difficulty]}
                                      </strong>
                                    )}

                                    <div className={view}>
                                      {view == 'display_slot'
                                        ? item.encounter_name
                                        : item.slot.replace('_', ' ')}
                                    </div>
                                  </div>
                                </div>

                                <div className='comment'>
                                  {(wish?.comment || editable) &&
                                    wish?.type &&
                                    wish?.type !== 'none' && (
                                      <Tooltip
                                        title={wish?.comment}
                                        placement='top'
                                        onClick={() => {
                                          if (editable)
                                            setEditingComment([item, wish])
                                        }}
                                      >
                                        <Icon
                                          type='message'
                                          className={
                                            wish?.comment ? 'active' : ''
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </div>

                                <div className='upgrade'>
                                  {wish?.upgrade_level &&
                                  wish.upgrade_level !== 'none' ? (
                                    <Tooltip
                                      title={wish.upgrade_level}
                                      overlayStyle={{
                                        textTransform: 'capitalize',
                                      }}
                                      placement='top'
                                    >
                                      <span
                                        className={`priority ${wish.upgrade_level}`}
                                      />
                                    </Tooltip>
                                  ) : !wishEmpty(wish) ? (
                                    <div>
                                      {editable &&
                                      wish?.type === 'percentage' ? (
                                        <span className='custom-percentage-input'>
                                          <Input
                                            type='text'
                                            inputMode='numeric'
                                            value={wish.custom_percentage}
                                            maxLength={4}
                                            onChange={(e) =>
                                              setEdit(item, wish, {
                                                custom_percentage:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                          %
                                        </span>
                                      ) : (
                                        <span>
                                          {valueDisplay === 'percentage' ||
                                          wish.custom_percentage
                                            ? `${parseFloat(
                                                wish.custom_percentage ||
                                                  wish.percentage ||
                                                  0,
                                              ).toFixed(2)}%`
                                            : parseInt(wish.absolute)}
                                        </span>
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                                {!wishEmpty(wish) &&
                                  wish.type !== 'raidbots' &&
                                  wish.type !== 'qe' &&
                                  item.slot !== 'conduit' && (
                                    <Tooltip
                                      title='Manually edited'
                                      placement='left'
                                    >
                                      <div className='issue-indicator edited' />
                                    </Tooltip>
                                  )}
                                {!wishEmpty(wish) &&
                                  wish?.outdated &&
                                  wish.type === 'raidbots' && (
                                    <Tooltip title='Outdated' placement='left'>
                                      <div className='issue-indicator outdated' />
                                    </Tooltip>
                                  )}
                              </div>

                              <div className='edit-container'>
                                <ul className='upgrade-bar'>
                                  {['tiny', 'small', 'big', 'huge'].map(
                                    (level) => (
                                      <li
                                        key={level}
                                        className={`level ${
                                          currentSettingForWish(item, wish) ===
                                          level
                                            ? 'active'
                                            : ''
                                        }`}
                                        onClick={() => {
                                          if (wish?.type === level) {
                                            undoEdit(item, wish)
                                          } else {
                                            setEdit(item, wish, {
                                              upgrade_level: level,
                                              type: level,
                                              custom_percentage: null,
                                            })
                                          }
                                        }}
                                      >
                                        <span className={`priority ${level}`} />
                                        {level}
                                      </li>
                                    ),
                                  )}
                                  <li
                                    className={
                                      currentSettingForWish(item, wish) ===
                                      'percentage'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={() => {
                                      if (wish?.type === 'percentage') {
                                        undoEdit(item, wish)
                                      } else {
                                        setEdit(item, wish, {
                                          upgrade_level: null,
                                          type: 'percentage',
                                          custom_percentage:
                                            wish?.percentage || '',
                                        })
                                      }
                                    }}
                                  >
                                    %
                                  </li>
                                  {wish?.droptimizer_report && (
                                    <li
                                      className={`droptimizer ${
                                        ['raidbots', 'qe'].includes(
                                          currentSettingForWish(item, wish),
                                        )
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() => {
                                        if (
                                          wish?.type === 'raidbots' ||
                                          wish?.type === 'qe'
                                        ) {
                                          undoEdit(item, wish)
                                        } else {
                                          setEdit(item, wish, {
                                            upgrade_level: null,
                                            type:
                                              wish?.original_type === 'qe'
                                                ? 'qe'
                                                : 'raidbots',
                                            custom_percentage: null,
                                          })
                                        }
                                      }}
                                    >
                                      {wish?.original_type === 'qe' ? (
                                        <img
                                          title='Questionably Epic'
                                          src={qeLogo}
                                        ></img>
                                      ) : (
                                        <img
                                          title='Raidbots'
                                          src={raidbotsLogoSmall}
                                        ></img>
                                      )}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              })
            : null}
        </div>
      </div>
    )
  }
}

export default Wishlist
